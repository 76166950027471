import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {catchError, mergeMap, Observable, switchMap, throwError} from "rxjs";
import {SingleFileUploadModel, SingleImageUploadModel} from "./models/single-image-upload-model";
import {SingleFileUploadResModel, SingleImageUploadResModel} from "./models/single-image-upload-res-model";

export const CLOUD_FILE_UPLOAD_API_BASE_URL = new InjectionToken<string>('CLOUD_FILE_UPLOAD_API_BASE_URL');
export const BASE_API_URL = new InjectionToken<string>('BASE_API_URL');
export const STORAGE_FOLDER = new InjectionToken<string>('STORAGE_FOLDER');
export const IMAGE_TRANSFORMATIONS = new InjectionToken<string>('IMAGE_TRANSFORMATIONS');
export const REQUEST_FORMAT = new InjectionToken<string>('REQUEST_FORMAT');

@Injectable({
  providedIn: 'root'
})
export class CloudFileUploaderService {

  headers = new HttpHeaders({'accept':'text/json'});

  constructor(private http: HttpClient,
              @Inject(CLOUD_FILE_UPLOAD_API_BASE_URL) private cloudFileUploadApiBaseUrl: string,
              @Inject(BASE_API_URL) private apiUrl: string,
              @Inject(STORAGE_FOLDER) private storageFolder: string,
              @Inject(IMAGE_TRANSFORMATIONS) private imageTransformations: string,
              @Inject(REQUEST_FORMAT) private requestFormat: string,
  ){  }

  uploadSingleImage(model: SingleImageUploadModel): Observable<SingleImageUploadResModel> {

    var formData = new FormData();

    formData.append('file', model.file);
    formData.append('name', model.name);
/*
    formData.append('folder', environment.storageFolder);
    formData.append('imageTransformations', environment.imageTransformations);
    formData.append('requestFormat', environment.requestFormat);
*/
    formData.append('folder', this.storageFolder);
    formData.append('imageTransformations', this.imageTransformations);
    formData.append('requestFormat', this.requestFormat);

    return this.postCloudImageUploadSingle(formData);
  }

  uploadSingleFile(model: SingleFileUploadModel): Observable<SingleFileUploadResModel> {
    var formData = new FormData();

    formData.append('file', model.file);
    formData.append('name', model.name);
/*
    formData.append('folder', environment.storageFolder);
    formData.append('requestFormat', environment.requestFormat);
*/
    formData.append('folder', this.storageFolder);
    formData.append('requestFormat', this.requestFormat);

    return this.postCloudUploadSingle(formData);
  }

  private postCloudUploadSingle(formData: FormData) {
    return this.http.get(`${this.apiUrl}auth/CloudUpload/token`, {
      responseType: 'text'
    })
      .pipe(
        switchMap(token => {
            return this.http.post<SingleImageUploadResModel>(
              `${this.cloudFileUploadApiBaseUrl}CloudUpload/single`, formData, {
                headers: new HttpHeaders({'Authorization': `Bearer ${token}`})
              })
              .pipe(
                catchError(this.errorMgmt)
              );
          }
        ));
  }

  private postCloudImageUploadSingle(formData: FormData) {
    return this.http.get(`${this.apiUrl}auth/CloudUpload/token`, {
      responseType: 'text'
    })
      .pipe(
        switchMap(token => {
            return this.http.post<SingleImageUploadResModel>(
              `${this.cloudFileUploadApiBaseUrl}CloudUpload/single`, formData, {
                headers: new HttpHeaders({'accept':'text/json', 'Authorization': `Bearer ${token}`})
              })
              .pipe(
                catchError(this.errorMgmt)
              );
          }
        ));
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
