import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, map, mergeMap, of } from 'rxjs';
import { CartItem, ShoppingCart, UnitsOfMeasure } from 'src/app/Models/shopping-cart';
import { ShoppingCartService } from 'src/app/Services/shopping-cart.service';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from "@angular/material/snack-bar";
import {HttpErrorResponse} from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-to-cart-amount-update-message',
  templateUrl: './add-to-cart-amount-update-message.component.html',
  styleUrls: ['./add-to-cart-amount-update-message.component.scss'],
  animations: [

    // Trigger is used here
    trigger('animate', [
      state('green', style({
        'background-color': '#00D250',
        transform: 'translateX(0)'
      })),
      state('red', style({
        'background-color': '#f46f61',
        'color': '#ffffff',
        transform: 'translateX(0)'
      })),
      state('white', style({
        'background-color': '#ffffff',
        transform: 'translateX(0)'
      })),
      transition('green => red', animate(1200)),
      transition('red => green', animate(1000)),
    ])
  ]
})
export class AddToCartAmountUpdateMessageComponent implements OnInit{
  inputAnimate = '';
  isItem = true;
  itemCount!: number;
  @Input() itemCountFromQuickDetailWindow!: number;
  inventoryId!: string;
  cart!: ShoppingCart;
  cartItem!: CartItem;
  isMessageClosed = false;
  lessThanMinimumMsg = false;
  countExceededMsg = false;
  isModalClosed: boolean = false;
  @Input() itemId!: number;
  maxItemCount: number = environment.defaultCustomItemMaxCount;
  minItemCount: number = 1;
  @Input() minQuantity: number | null = null;
  @Input() maxQuantity: number | null = null;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public activeModal: NgbActiveModal,
              private _snackBar: MatSnackBar,
              public shoppingCartService: ShoppingCartService) {}

  ngOnInit(): void {
    this.shoppingCartService.loadShoppingCart().subscribe({
      next : (cart) => {
        this.cart = cart;
        this.cartItem = this.cart.cartItems.filter(item => this.filterItemId(item.product.inventoryId) == this.itemId)[0];
        this.itemCount = this.itemCountFromQuickDetailWindow ? this.itemCountFromQuickDetailWindow : this.cartItem.quantity;
        this.inventoryId = this.cartItem.product.inventoryId;
      }
    });
  }

  filterItemId(invId: string): number|null {
    this.inventoryId = invId;
    const id = Number(invId.split('-')[1]);
    if(id == this.itemId) {
      return id;
    }
    return null;
  }

  updateUnitCount() {

    if (this.maxQuantity && this.maxQuantity < this.itemCount) {
      this.addingMsg(`You cannot purchase more than ${ this.maxQuantity } for this item!`, undefined, {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: 'light-error-snackbar',
      }, 5000);
      return;
    }

    if (this.minQuantity && this.minQuantity > this.itemCount) {
      this.addingMsg(`You cannot purchase less than ${ this.minQuantity } for this item!`, undefined, {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: 'light-error-snackbar',
      }, 5000);
      return;
    }
    
    of(this.cart).pipe(
      map(c => c.cartItems.filter(i => i.id == this.cartItem.id)[0]),
      map(c => c.quantity = this.itemCount),
      filter(newUnits => newUnits > 0),
      mergeMap(newUnits => this.shoppingCartService.updateShoppingCartItemUnits(this.cart.id, this.cartItem.id, {
        units: newUnits,
        unitsOfMeasure: UnitsOfMeasure.qty
      }))
    ).subscribe(
      {
        next: (cart) => {
          this.cart = cart;
          this.isModalClosed = true;
          this.addingMsg(`Cart has been updated!`, undefined, {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: 'light-success-snackbar',
          }, 5000);
          setTimeout(() => {
            this.activeModal.dismiss('Close click');
          }, 2500)
        },
        error : (error : HttpErrorResponse) => {
          this.addingMsg(`Cannot add to cart, ${this.cartItem.product.name}, ${this.shoppingCartService.mapAddToCartError(error)}`, undefined, {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: 'light-error-snackbar',
          }, 5000);
        }
      }
    );
  }

  decrementCount(plusOrMinus: number) {
    if(this.minQuantity){
      this.minItemCount = this.minQuantity;
    }

    if (this.itemCount > this.minItemCount) {
      this.itemCount += plusOrMinus;
      this.countExceededMsg = false;
    } else {
      this.lessThanMinimumMsg = true;
    }
    this.inputAnimate = plusOrMinus > 0 ? 'green' : 'red';
    setTimeout(() => {
      this.inputAnimate = 'white';
    }, 500);
  }

  incrementCount(plusOrMinus: number) {
    if(this.maxQuantity){
      this.maxItemCount = this.maxQuantity;
    }

    if (this.itemCount >= 1 && this.itemCount < this.maxItemCount) {
      this.itemCount += plusOrMinus;
      this.lessThanMinimumMsg = false;
    } else {
      this.countExceededMsg = true;
    }
    this.inputAnimate = plusOrMinus > 0 ? 'green' : 'red';
    setTimeout(() => {
      this.inputAnimate = 'white';
    }, 500);
  }

  addingMsg(message: string, action = '', config?: MatSnackBarConfig, timeout? : number) {
    this._snackBar.open(message, action, config);
    setTimeout(() => {
      this._snackBar.dismiss();
    }, timeout ? timeout : 3500);
  }

  closeMessage() {
    this.isMessageClosed = false;
    this.countExceededMsg = false;
    this.lessThanMinimumMsg = false;
  }

}
