import {APP_INITIALIZER, DEFAULT_CURRENCY_CODE, InjectionToken, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ComponentsModule} from './components/components.module';
import {NgbAlertModule, NgbModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {IconsModule} from './icons/icons.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GiftyAppService} from './Services/gifty-app.service';
import {SpinnerService} from "@xyg/spinner";
import {
  ProductDetailQuickViewService
} from './Services/product-detail-quick-view-service/product-detail-quick-view.service';
import {allIcons, NgxBootstrapIconsModule} from 'ngx-bootstrap-icons';
import {AccessTokenInterceptor} from './shared/access-token.interceptor';
import {AppAuthService} from './shared/app-auth.service';
import {concat, Observable, tap} from 'rxjs';
import {AppUserService} from './shared/app-user.service';
import {CommonsModule} from "./commons/commons.module";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {HeaderComponent} from "./shared/header/header.component";
import {FooterComponent} from "./shared/footer/footer.component";
import {UserMenusPopoverComponent} from "./shared/user-menus-popover/user-menus-popover.component";
import {AutocompleteLibModule} from "angular-ng-autocomplete";
import {FormsModule} from "@angular/forms";
import {TruncatePipe} from "./pipes/truncate.pipe";
import {HomeService} from './Services/home.service';
import {AppConfigLoader} from './app.config-loader';
import { MatNativeDateModule } from '@angular/material/core';
import { RouterEventService } from './shared/RouterEvent.service';
import { MatMenuModule } from '@angular/material/menu';
import {environment} from "../environments/environment";
import { MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/datepicker';
import {
  CLOUD_FILE_UPLOAD_API_BASE_URL,
  BASE_API_URL,
  CloudFileUploaderService,
  IMAGE_TRANSFORMATIONS, REQUEST_FORMAT,
  STORAGE_FOLDER
} from "@xyg/cloud-file-uploader";
import { SocialChatComponent } from './social-chat/social-chat.component';
import { MatTooltipModule } from '@angular/material/tooltip';

export const API_BASE_URL = new InjectionToken<string>('BASE_API_URL');


@NgModule({
  declarations: [
    SocialChatComponent,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    UserMenusPopoverComponent,
    TruncatePipe
  ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        ComponentsModule,
        NgbModule,
        NgbPaginationModule,
        NgbAlertModule,
        IconsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgxBootstrapIconsModule.pick(allIcons),
        CommonsModule,
        FormsModule,
        MatProgressBarModule,
        AutocompleteLibModule,
        MatNativeDateModule,
        MatMenuModule,
        MatTooltipModule
    ],
  providers: [
    MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
    {
      provide: 'BASE_API_URL',
      useFactory: () => environment.apiBaseUrl,
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'LKR' },
    {
      provide: APP_INITIALIZER,
      deps: [AppAuthService, AppUserService],
      useFactory: initializeAppFactory,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptor,
      multi: true
    },
    {
      provide: CLOUD_FILE_UPLOAD_API_BASE_URL,
      useFactory: () => environment.imageUploadAdminApiBaseUrl
    },
    {
      provide: BASE_API_URL,
      useFactory: () => environment.apiBaseUrl
    },
    {
      provide: STORAGE_FOLDER,
      useFactory: () => environment.storageFolder
    },
    {
      provide: IMAGE_TRANSFORMATIONS,
      useFactory: () => environment.imageTransformations
    },
    {
      provide: REQUEST_FORMAT,
      useFactory: () => environment.requestFormat
    },
    AppAuthService,
    GiftyAppService,
    SpinnerService,
    ProductDetailQuickViewService,
    HomeService,
    RouterEventService,
    CloudFileUploaderService
  ],
  bootstrap: [AppComponent],
})

export class AppModule {}

function initializeAppFactory(appAuthService: AppAuthService, appUserService: AppUserService): () => Observable<any> | Promise<unknown> {
  return () => concat(
    AppConfigLoader.configLoader(),
    appAuthService.initializeLoggedIn()
      .pipe(
        tap((isLoggedIn: Boolean) => {
          if (isLoggedIn) {
            console.log('Loading current user...');
            appUserService.loadCurrentUser().subscribe(value => {
              appUserService.setPicture(value.picture);
            });
          }
        })
      )
  )
}
