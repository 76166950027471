import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {AddCartItem, InventoryEntry, ItemMessage, ShoppingCart} from '../Models/shopping-cart';
import {catchError, map, Observable, of, Subject, tap} from 'rxjs';
import {ShippingMethod, ShippingMethodPost, ShippingMethodUpdate, ShippingMethodWithZones} from "../Models/shipping-method";
import {CheckoutCartForm} from "../Models/checkout-cart-form";
import {CompletedCheckoutResult} from "../Models/completed-checkout-result";
import {AppAuthService} from '../shared/app-auth.service';
import {CheckoutOrder} from "../Models/checkout-order";
import { ShippingLocation, ShippingLocationPost, ShippingLocationPut } from '../Models/shipping-location';
import { ShippingZone, ShippingZonePost, ShippingZoneUpdate } from '../Models/shipping-zone';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  headers = new HttpHeaders({'accept': 'text/json'});
  private _cartItemCountChange$: Subject<number> = new Subject<number>();

  constructor(private http: HttpClient, private authService: AppAuthService) {
    this.authService.signInSignOutEvents.subscribe({
      next: (res) => {
        this.currentItemCount().subscribe(() => {
          this.refreshCurrentItemCount();
        });
      }
    })
  }

  cartItemCountChange(): Observable<number> {
    return this._cartItemCountChange$.asObservable();
  }

  triggerCartItemCount(value: number) {
    this._cartItemCountChange$.next(value);
  }

  currentItemCount(): Observable<number> {
    return this.http.get<{ count: number }>
    (environment.apiBaseUrl + `shopping-cart/current-item-count`, {headers: this.headers})
      .pipe(
        catchError((err, caught) => of({count: 0})),
        map(value => value.count)
      );
  }
  refreshCurrentItemCount(): void {
    this.currentItemCount().subscribe({
      next: value => this._cartItemCountChange$.next(value)
    });
  }

  loadShoppingCart(): Observable<ShoppingCart> {
    return this.http.post<ShoppingCart>(environment.apiBaseUrl + `shopping-cart`, { headers: this.headers });
  }

  getShoppingCart(cartId: number): Observable<ShoppingCart> {
    return this.http.get<ShoppingCart>(environment.apiBaseUrl + `shopping-cart/${cartId}`, { headers: this.headers });
  }

  updateShoppingCartItemUnits(cartId: number, itemId: number, units: InventoryEntry): Observable<ShoppingCart> {
    return this.http.put<ShoppingCart>(environment.apiBaseUrl + `shopping-cart/${cartId}/cart-item/${itemId}/units`,
         units, { headers: this.headers });
  }

  noExistingGiftItems(cartId: number, inventoryId: string): Observable<boolean> {
      return this.http.get<boolean>(environment.apiBaseUrl + `shopping-cart/${cartId}/inventory/${inventoryId}`)
  }

  addCartItemByInventoryId(cartId: number, inventoryId: string, units: InventoryEntry): Observable<ShoppingCart> {
    return this.http.put<ShoppingCart>(environment.apiBaseUrl + `shopping-cart/${environment.inventoryProvider}/${cartId}/inventory/${inventoryId}`,
    units,
    { headers: this.headers })
    .pipe(
      tap(cart => this._cartItemCountChange$.next(cart.cartItems.length))
      );
  }

  addCartItemByInventoryIdWithMessage(cartId: number, inventoryId: string, item: AddCartItem): Observable<ShoppingCart> {
    return this.http.put<ShoppingCart>(environment.apiBaseUrl + `shopping-cart/${environment.inventoryProvider}/${cartId}/inventory-with-message/${inventoryId}`,
    item,
    { headers: this.headers })
    .pipe(
      tap(cart => this._cartItemCountChange$.next(cart.cartItems.length))
    );
  }

  updateCartItemMessage(itemMessage: ItemMessage): Observable<boolean> {
    return this.http.put<boolean>(
      environment.apiBaseUrl + `shopping-cart/cart-item-message`,
      itemMessage,
      { headers: this.headers }
    );
  }

  mapAddToCartError(error : HttpErrorResponse): string {
    if(error.error &&  typeof(error.error) == 'string'){
      var text = (error.error as string).replaceAll('\"', '')
      switch (text){
        case 'ITEM_NOT_AVAILABLE': return 'Item not available';
        case 'ITEM_STOCK_ENOUGH': return 'Item stock not enough';
        case 'ITEM_SOLD_OUT': return 'Item sold out';
      }
    }
    else if(error.error.detail &&  typeof(error.error.detail) == 'string'){
      console.debug('error.error.detail ', error.error.detail);
      var text = (error.error.detail as string).replaceAll('\"', '')
      switch (text){
        case 'ITEM_NOT_AVAILABLE': return 'Item not available';
        case 'ITEM_STOCK_ENOUGH': return 'Item stock not enough';
        case 'ITEM_SOLD_OUT': return 'Item sold out';
      }
    }
    else if(!error.error.succeeded &&  typeof(error.error.errors) == 'object'){
      console.debug('error.error ', error.error);
      var errors = (error.error.errors as any[]);
      switch (errors[0].description){
        case 'ITEM_NOT_AVAILABLE': return 'One or mote item(s) are not available.';
        case 'ITEM_STOCK_ENOUGH': return 'One or mote item(s) stock are not enough.';
        case 'ITEM_SOLD_OUT': return 'One or mote item(s) are sold out.';
        default: return errors[0].description;
      }
    }
    return '';
  }

  mapApiCodeDescriptionError(error : HttpErrorResponse): string {
    if(error.error &&  typeof(error.error) == 'object'){
      if(error.error.description){
        return error.error.description;
      }
    }
    return '';
  }

  deleteCartItem(cartId: number, itemId: number): Observable<ShoppingCart> {
    return this.http.delete<ShoppingCart>(environment.apiBaseUrl + `shopping-cart/${cartId}/cart-item/${itemId}`,
    { headers: this.headers })
    .pipe(
      tap(cart => this._cartItemCountChange$.next(cart.cartItems.length))
    );
  }

  checkoutCartFirstStep(cartId: number, checkoutCartForm: CheckoutCartForm): Observable<CompletedCheckoutResult> {
    return this.http.post<CompletedCheckoutResult>(environment.apiBaseUrl + `shopping-cart/${cartId}/checkout-cart-first-step`,
      checkoutCartForm,
      { headers: this.headers });
  }

  applyPromotionCode(cartId: number, promoCode : string): Observable<ShoppingCart> {
    return this.http.post<ShoppingCart>(
      environment.apiBaseUrl + `shopping-cart/${cartId}/apply-promotion-code/${encodeURIComponent(promoCode)}`,
      {}
    );
  }

  removePromotionCode(cartId: number): Observable<ShoppingCart> {
    return this.http.post<ShoppingCart>(
      environment.apiBaseUrl + `shopping-cart/${cartId}/remove-promotion-code`,
      {}
    );
  }

  getDeliveryLocationTypes(): Observable<any> {
    return this.http.get<any>(environment.apiBaseUrl + `api/deliveryLocationTypes`)
  }

  //Shipping Locations
  getShippingLocations(): Observable<ShippingLocation[]> {
    return this.http.get<ShippingLocation[]>(environment.apiBaseUrl + `api/shippingLocations`);
  }

  getShippingLocation(id: number): Observable<ShippingLocation> {
    return this.http.get<ShippingLocation>(environment.apiBaseUrl + `api/shippingLocations/${id}`);
  }

  createShippingLocation(shippingLocation: ShippingLocationPost): Observable<ShippingLocation> {
    return this.http.post<ShippingLocation>(environment.apiBaseUrl + `api/shippingLocations`, shippingLocation);
  }

  updateShippingLocation(id: number, shippingLocation: ShippingLocationPut): Observable<ShippingLocation> {
    return this.http.put<ShippingLocation>(environment.apiBaseUrl + `api/shippingLocations/${id}`, shippingLocation);
  }

  deleteShippingLocation(id: number): Observable<any> {
    return this.http.delete<any>(environment.apiBaseUrl + `api/shippingLocations/${id}`);
  }

  //Shipping Zones
  getShippingZones(): Observable<ShippingZone[]> {
    return this.http.get<ShippingZone[]>(environment.apiBaseUrl + `api/shippingZones`);
  }

  createShippingZone(zone: ShippingZonePost): Observable<ShippingZone> {
    return this.http.post<ShippingZone>(environment.apiBaseUrl + `api/shippingZones`, zone);
  }

  updateShippingZone(id: number, shippingZone: ShippingZoneUpdate): Observable<ShippingZone> {
    return this.http.put<ShippingZone>(environment.apiBaseUrl + `api/shippingZones/${id}`, shippingZone);
  }

  deleteShippingZone(id: number): Observable<any> {
    return this.http.delete<any>(environment.apiBaseUrl + `api/shippingZones/${id}`);
  }
 
  //Shipping Methods
  getShippingMethods(): Observable<ShippingMethod[]> {
    return this.http.get<ShippingMethod[]>(environment.apiBaseUrl + `api/ShippingMethods`, {headers: this.headers});
  }

  getShippingMethodsWithZones(): Observable<ShippingMethodWithZones[]> {
    return this.http.get<ShippingMethodWithZones[]>(environment.apiBaseUrl + `api/ShippingMethods/with-zones`);
  }

  getShippingMethodWithZones(id: number): Observable<ShippingMethodWithZones> {
    return this.http.get<ShippingMethodWithZones>(environment.apiBaseUrl + `api/ShippingMethods/${id}/with-zones`);
  }

  createShippingMethod(method: ShippingMethodPost): Observable<ShippingMethod> {
    return this.http.post<ShippingMethod>(environment.apiBaseUrl + `api/ShippingMethods`, method);
  }

  updateShippingMethod(id: number, shippingMethod: ShippingMethodUpdate):Observable<ShippingMethod> {
    return this.http.put<ShippingMethod>(environment.apiBaseUrl + `api/ShippingMethods/${id}`, shippingMethod);
  }

  deleteShippingMethod(id: number): Observable<any> {
    return this.http.delete<any>(environment.apiBaseUrl + `api/ShippingMethods/${id}`);
  }
}
