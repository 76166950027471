<xyg-loading-spinner></xyg-loading-spinner>
<div *ngIf="itemCount" id="quick-view-detail-wrapper" class="quick-view-detail-wrapper position-relative">
    <div class="modal-header border-0">
        <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.close()"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 col-xl-6 image-slider-wrapper px-4 mb-3">
              <div class="gallery-container">
                  <div class="product-gallery__featured">
                    <owl-carousel-o [options]="imagesSlider" (changed)="slideChanged($event)" #mainSlider>
                      <ng-container *ngFor="let image of images; let i = index">
                        <ng-template carouselSlide [id]="i.toString()">
                          <img alt="{{ getAltMsg(image) }}" [src]="image | cloudFileToUrl : 'thumb'" class="img-fluid">
                        </ng-template>
                      </ng-container>
                    </owl-carousel-o>
                  </div>

                  <div class="product-gallery__carousel">
                    <owl-carousel-o [options]="thumbnailsSlider" #thumbSlider>
                      <ng-container *ngFor="let image of images; let i = index">
                        <ng-template carouselSlide [id]="i.toString()" [width]="imageWidth">
                          <a (click)="mainSlider.to(i.toString()); changeImage(i)">
                            <img alt="{{ getAltMsg(image) }}" [src]="image | cloudFileToUrl : 'thumb'"
                            [ngClass]="{'product-gallery__carousel-item--active':selectedImage === i}">
                          </a>
                        </ng-template>
                      </ng-container>
                    </owl-carousel-o>
                  </div>
                </div>
            </div>
            <div class="col-12 col-xl-6 detail-wrapper px-4">
                <h3 class="modal-title secondary-font mb-3 text-dark" *ngIf="isItem">{{productItem?.itemName}}</h3>
                <h3 class="modal-title secondary-font mb-3 text-dark" *ngIf="!isItem">{{productPackage?.packageName}}</h3>
                <div class="description-text mb-5 text-dark">
                    <p class="fs-6" *ngIf="isItem">
                        {{productItem?.itemShortDesc}}
                    </p>
                    <p class="fs-6" *ngIf="!isItem">
                        {{productPackage?.shortDescription}}
                    </p>
                </div>
                <div class="price-detail-wrapper d-flex  justify-content-between align-items-center">
                    <div class="price-details">
                      <div *ngIf="reducedPrice">
                        <p class="product-price fw-bold fs-3 text-dark mb-0">{{ isItem && productItem ?
                          productItem.price?.currency :
                          productPackage.price?.currency }} {{ reducedPrice | number:'1.2-2'}}&nbsp;
                          <span class="product-price fw-bold fs-3 text-dark"  *ngIf="isItem && productItem"><s>{{ productItem?.price | customPriceFormat }}</s></span>
                          <span class="product-price fw-bold fs-3 text-dark"  *ngIf="!isItem && productPackage"><s>{{ productPackage?.price | customPriceFormat }}</s></span>
                        </p>
                      </div>
                      <div *ngIf="!reducedPrice">
                        <p class="product-price fw-bold fs-3 text-dark mb-0"  *ngIf="isItem && productItem">{{ productItem?.price | customPriceFormat }}</p>
                        <p class="product-price fw-bold fs-3 text-dark mb-0"  *ngIf="!isItem && productPackage">{{ productPackage?.price | customPriceFormat }}</p>
                      </div>
                    </div>
                    <div class="stock-details">
                      <div class="d-flex align-items-center">
                        <!--Customizable Cake-->
                        @if(!isOutStock && isCustomizableCake){
                          <button class="btn border border-2 border-primary btn-primary rounded-2 custom-wording-button" (click)="toggleMessageBox()" matTooltip="Add a customized text to the cake." matTooltipPosition="above">
                            @if(showMessageBox){
                              Save Cake Wording
                            } @else {
                              @if(message.length > 0){
                                Edit Cake Wording
                              } @else {
                                Add Cake Wording
                              }
                            }
                          </button>
                        }
                        <span class="stock-label border border-2 {{!isOutStock ? 'border-success text-success' : 'border-danger text-danger'}} px-3 py-1 rounded-2">{{!isOutStock ? 'In Stock': 'Out of Stock'}}</span>
                      </div>
                    </div>
                </div>
                <hr class="border border-bottom border-mute opacity-50">
                @if (showMessageBox) {
                  <mat-form-field id="message-form-field" class="w-100" appearance="outline">
                    <mat-label id="message-label">Type Cake Wording</mat-label>
                    <textarea style="resize: none;" id="message-text-area"
                      matInput
                      placeholder="Type your wording with max 20 characters..."
                      rows="2"
                      maxlength="20"
                      [(ngModel)]="message"
                      [@fadeInOut]="showMessageBox ? 'visible' : 'hidden'"
                    ></textarea>
                    <mat-hint class="custom-wording-hint" align="end">{{message.length}} / 20</mat-hint>
                  </mat-form-field>
                } @else {
                  @if(message.length > 0){
                    <span class="badge text-bg-light border border-1 fs-6" style="width: 100%; height:50px; align-content: center; text-align: center;">{{ message }}</span>
                    <br><br>
                  }
                }
                <div class="add-to-box-wrapper d-flex justify-content-between align-items-center mb-4">
                    <div class="counder-wrapper me-3">
                        <div class="input-group">
                            <button id="counter-decrement-btn" (click)="decrementCont()"
                                class="btn btn-light border border-1 border-dark rounded-circle px-3 me-2 counter-decrement-btn">
                                <!-- <i-bs name="dash-lg"></i-bs> -->
                                <span class="fs-5">-</span>
                            </button>
                            <input id="counter-data-field" type="number" [@animate]='inputAnimate'
                                class="form-control rounded-pill border-1 border-dark mx-1 text-center"
                                aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" min="1"
                                readonly value="{{itemCount}}">
                            <button id="counter-increment-btn" (click)="incrementCont()"
                                class="btn btn-light border border-1 border-dark rounded-circle px-3 ms-2 counter-increment-btn">
                                <!-- <i-bs name="plus-lg"></i-bs> -->
                                <span class="fs-5">+</span>
                            </button>
                        </div>
                    </div>
                    <div class="button-wrapper flex-fill">
                        <button class="btn btn-primary rounded-pill w-100" xygThrottleClick [throttleTime]="800"
                            (throttleClick)="addToCart()" *ngIf="isItem"
                            [disabled]="itemCount == 0 || isOutStock">Add to Box</button>
                        <button class="btn btn-primary rounded-pill w-100" xygThrottleClick [throttleTime]="800"
                            (throttleClick)="addToCart()" *ngIf="!isItem"
                            [disabled]="itemCount == 0 || isOutStock">Add to Cart</button>
                    </div>
                </div>
                <div *ngIf="additionalDetails" class="additional-details-wrapper text-dark mb-4">
                    <h5 class="text-uppercase">Details</h5>
                    <ul>
                        <li *ngFor="let details of additionalDetails;let i = index" [innerHTML]="details"></li>
                    </ul>
                </div>
                <!-- <div class="care-details-wrapper text-dark mb-4">
                    <h5 class="text-uppercase">Care</h5>
                </div> -->
                <!-- TODO: Add this care tag later -->
            </div>
        </div>
    </div>
    <div *ngIf="isMessageClosed && addingCartSuccess" id="quick-detail-message-wrapper" class="quick-detail-message-wrapper position-absolute bg-success bottom-0 start-50 translate-middle-x w-100 p-2">
        <div class="d-flex justify-content-between align-items-center">
            <div class="message-text">
                <a (click)="closeMessage()"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg me-2 text-light" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                  </svg>
                </a>
                 <span *ngIf="productItem"> {{productItem.itemName}} added!</span>
                 <span *ngIf="productPackage"> {{productPackage.packageName}} added!</span>
            </div>
            <div class="view-cart-button">
                <div id="message-wrapper-view-cart-btn" class="btn btn-outline-primary btn-light rounded-pill"
                [routerLink]="'/cart'" (click)="activeModal.dismiss('Added to shopping cart')">View cart</div>
            </div>
        </div>
    </div>
    <div *ngIf="countExceededMsg || lessThanMinimumMsg" id="quick-detail-message-wrapper" class="quick-detail-message-wrapper position-absolute bg-success bottom-0 start-50 translate-middle-x w-100 p-2">
        <div class="d-flex justify-content-between align-items-center">
            <div class="message-text">
                <a (click)="closeMessage()"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg me-2 text-light" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                  </svg>
                </a>
                 <span *ngIf="countExceededMsg"> Maximum Quantity Exceeded: You can purchase a maximum of {{ maxItemCount }} units at a time. For bulk order inquiries and special discounts, please contact our sales team.! </span>
                 <span *ngIf="lessThanMinimumMsg"> Item count can't be less than {{minItemCount}}! </span>
            </div>
        </div>
    </div>
</div>
